// Styles pertaining to the admin page of the survey - They do not use the existing layout framework.

.householdcontactinfo	 {
display: flex;
flex-direction: row;
}

.householdcontactinfo__telephone, .householdcontactinfo__email {
	width: 50%;
	flex:1 1 auto;
}

.householdcontactinfo__telephone > legend, .householdcontactinfo__email  > legend {
	padding-top:1rem;
}

.householdcontactinfo__telephone {
	padding-right: 1rem;
}

@media (max-width:500px) {
	.householdcontactinfo__telephone, .householdcontactinfo__email {
		width: 100%;
		flex:1 1 100%;
		padding-right: 0rem;
	}

	.householdcontactinfo	 {
	flex-direction: column;;
	}
}

.householdcontactinfo__telephone > input {

margin:0!important;
width: 100%!important;
padding-right:1rem!important;

}
