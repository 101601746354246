.footer {
  .container {
    text-align: right;
    @include small {
      text-align: left;
    }
    .grid.navigation {
      clear: both;
      padding-top: 10px;
      justify-content: space-between;
      @include small {
        flex-direction: column-reverse;
      }
    }
  }
  p {
    font-size: 1.1em;
    margin-bottom: 0;
    @include small {
      display: block;
    }
  }
}
.bottom-footer {
  background-color: $primary-colour;
  border-top: 2px solid $black;
  height: auto;
  ul, li, a, p {
    color: $white;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
  }
  p {
    padding: 0.5em 0;
  }
  .tmr-footer-links {
    text-align: center;
    ul {
      text-decoration: none;
      list-style-type: none;
    }
    li {
      color: $white;
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
      display: inline;
      margin-right: 1em;
      padding-right: 1em;
      border-right: 1px solid $white;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &.no-border {
        border-right: none;
      }
    }
  }
}
