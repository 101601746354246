.whats-involved {
	width:100%;
	display: inline-block;
}

.whats-involved div {
	margin:5px;
}

div.grid div.width-48 {
	margin:1%;
}

div.grid div.width-48 img {
margin-top: 4px;
}

div.grid div.width-48 h3 {
	font-weight:600;
	font-size: 20px;
	color: $very-dark-blue;
}

div.grid div.width-48 h4 {
	font-size: 17px;
	color: $very-dark-blue;
}

.frontpage-headingthree h3 {
	font-weight:600;
	font-size: 20px;
	color: $very-dark-blue;
}
