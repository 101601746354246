.error-message {
  width: 100%;
  font-weight: bold;
  p {
    padding: 0.5em;
    border-width: 0 0 0 0.35rem;
    color: $primary-font-colour;
    font-weight: normal;
    clear: both;
    margin: 1em 0;
    background-color: scale-color($error-message-red, $lightness: 60%);
  }
}

.error-message__login p {
  font-weight: 700;
  border-radius: 10px;
  margin: 10px;
  margin-top: 20px;
  padding: 20px;
  color: #80322b;
}
