// Full width question layout

.full-width legend {
    padding-top :20px;
    width       : 100%;
}

div.full-width + fieldset > legend {
  padding-top   :20px;
  margin-bottom :0;
}

// Accordion question layout
