/* Colour names */
/* Shades */
$black:            #222222;
$very-dark-grey:   #3E3E3E;
$grey:             #AAAAAA;
$light-grey:       #8C8C8C;
$white:            #FDFDFD;

/* Colours */
$very-dark-blue:    #003C69;
$strong-blue:       #007CB5;
$strong-red:				#E20000;
$dark-blue:         #006B9C;
$pure-blue:         #00ACEE;
$bright-blue:       #0D8BFF;
$mod-blue:          #59AACF;
$dark-mod-blue:     #3B5998;
$desat-dark-blue:   #668AA6;
$grey-blue:         #99B1C3;
$light-grey-blue:   #CCD8E1;
$very-pale-blue:    #daedf5;

$strong-green:      #7AB800;
$bright-green:      #359600;
$desat-mod-green:   #92C669;
$desat-light-green: #ADC669;
$very-soft-green:   #C9E3B4;
$very-soft-red:   	#FFCBCB;
$light-grey-green:  #E4F1D9;

$bright-red:        #F22613;
$bright-orange:     #F79427;
$dark-pink:         #A70243;
$light-grey-yellow: #E9E8D7;

$speech-bubble-orange: #FD8204;
$speech-bubble-bright-blue: #1675a9;
$speech-bubble-pale-blue: #486e90;
$speech-bubble-green: #80bd01;

/* Theme colours */
$tmr-orange:    $speech-bubble-orange;
$tmr-maroon:    $dark-pink;
$tmr-green:     $speech-bubble-green;
$tmr-blue:      $strong-blue;
$tmr-stone:     $light-grey-yellow;

$tmr-theme-01: #003c69; //TMR DARK BLUE
$tmr-theme-02: #fd8204; //TMR ORANGE
$tmr-theme-03: #1675a9; //TMR BRIGHT BLUE
$tmr-theme-04: #80bd01; //TMR GREEN
$tmr-theme-05: #486e90; //TMR GREY BLUE
$tmr-theme-06: #f0f0f0; //LIGHT GREY
$tmr-theme-07: #cacaca; //SLIGHTLY DARKER GREY

/* Font families */
$trebuchet-font-stack:    'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
$verdana-font-stack:      Verdana, Helvetica, Arial, sans serif;
$fontawesome:             "FontAwesome";

/* Theme defaults */
$primary-colour:            $very-dark-blue;
$secondary-colour:          $strong-green;
$primary-font-colour:       $black;
$secondary-font-colour:     $white;
$primary-font:              $verdana-font-stack;
$secondary-font:            $trebuchet-font-stack;

/* Functional defaults */
$link-blue:                 $strong-blue;
$body-bg:                   $white;
$unselected-grey:           $light-grey;
$error-message-red:         $bright-red;
$error-message-blue:        $bright-blue;
$error-message-green:       $bright-green;
$error-message-orange:      $bright-orange;

//BTNSW - new
$tooltip-color: $tmr-blue;
$info-dots-color: $bright-green;
$checkbox-selected-color: $bright-green;
$checkbox-unselected-color: $unselected-grey;
$accordion-unselected-bgcolor: $tmr-theme-03;
$accordion-selected-bgcolor: $primary-colour;
$new-thing-here-text-color: $primary-colour;
$new-thing-here-box-color: $tmr-blue;
$add-thing-text-color: $primary-colour;
$add-thing-box-color: $tmr-blue;
$form-inner-bgcolor: #e9f5f9;
$form-outer-bgcolor: $very-pale-blue;
$home-table-header-bgcolor: #eee;
$home-table-border: border-left;
$household-row-border-color: $strong-green;
$household-row-bgcolor: $very-soft-green;
$travel-note-row-border-color: $tmr-blue;
$travel-note-row-bgcolor: $very-pale-blue;

/* Animation tuning */

/* accordion expand/collapse */
$slide-down-duration:           250ms;      // default is 300ms

/* accordion item delete */
$scale-fade-duration:           350ms;      // default is 500ms

/* accordion item create */
$flip-in-duration:              550ms;      // default is 550ms

/* breakpoints */
$mobile-width:   500px;
$tablet-width:   820px;
$desktop-width: 1024px;
