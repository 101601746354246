.underlined-subheading {
	margin-left: 10px;
	margin-right: 10px;
}
.underlined-subheading hr {
	border:0px;
	border-bottom:3px solid $very-dark-blue;
  margin-bottom: 10px;
	margin-top: 0px;
}

div.underlined-subheading h2 {
margin-bottom: 0px;
}
