input[type="radio"].ng-untouched {
    box-shadow: none;
}

//This overrides the default placeholder text color to something darker in order to comply with WCAG 2.0 AA.
input::placeholder {
    color: #757575;
}

input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #757575;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
}

input:-ms-input-placeholder {
    /* IE 10+ */
    color: #757575;
}

input:-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
}

input[readonly].flatpickr {
    background-color: #FFFFFF;
}

// Border override for textarea and select inputs for darker contrast
input, textarea {
    border: 1px solid $light-grey !important;
}

select {
    border: 1px solid $light-grey !important;
}

// Change error message text color
.error-message {
    color: black;
}