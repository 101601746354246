.button {
  background-color: $tmr-theme-03;
}

.button:hover, .button:focus {
  background-color: $tmr-theme-01;
}

.button {
  @include colorTransition(200ms);
  &.green {
//    background-color: $tmr-green;
    &:hover,
    &:focus {
//      background-color: $tmr-blue;
    }
  }
  &.blue {
    background-color: $tmr-theme-03;
    &:hover,
    &:focus {
    background-color: $tmr-theme-01;
    }
  }
  &.white {
    background-color: $white;
    color: $tmr-maroon;
    border: 1px solid $tmr-maroon;
    &:hover,
    &:focus {
      color: $white;
      background-color: $tmr-maroon;
    }
  }
}
