.vehicle-type-question {
    label {
        margin: 0;
    }
    label:first-child {
        margin-top: 1em;
    }
    select {
        margin: 0;
        margin-bottom: 1em;
        width: 100%;
    }
}
