.householdmembers {

width:100%;
margin-top:4px;
margin-bottom:4px;
display: block;
padding:10px;
background-color: $tmr-theme-06;
border: 1px solid $tmr-theme-07;
}

.householdmembers input[type="text"] {
	float:left;
	display: inline-block;
	margin:0px;
	height:2.95rem;
}

.householdmembers button {
	margin:0px;
	float:right;
	display: inline-block;
	font-size: 1rem;
}

.householdmembers-name {
    width: 85.8%;
}

.householdmembers-addpersonbtn {
	margin-top: 4px;
}
