.header h1 {
	font-size: 40px!important;
	font-weight: 700;
}

.header h1.header__heading__subheading {
	font-size: 18px!important;
}

@media (max-width:999px) {
	.header h1 {
		font-size: 30px!important;
	}

	body > div > div.content.banner.ng-scope > div > div > div.width-55 > img {
		width:220px!important;
		margin-top: 12px!important;
	}

}

@media (max-width:685px) {
	.header h1 {
		font-size: 20px!important;
	}

	.header__heading__subheading {
		font-size: 12px!important;
	}

	body > div > div.content.banner.ng-scope > div > div > div.width-55 > img {
		width:175px!important;
		margin-top: 7px!important;
	}

}

@media (max-width:500px) {
		body > div > div.content.banner.ng-scope > div > div > div.width-55 {
			text-align: center;
	}
}

.page-title {

border-bottom: 3px solid #003c69;
padding-bottom: 15px;

}
