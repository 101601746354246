ee9.accordian-button-left {
  float:left;
  padding-left:1em;
}

.accordian-button-right {
  float:right;
}

.accordion {
  line-height: normal;
}

.accordion-title {
	@include colorTransition(.3);
	border: 1px solid $accordion-unselected-bgcolor;
	background-color: $accordion-unselected-bgcolor;
	padding: 0.75em;
	z-index: 1;
	color: $secondary-font-colour;
	font-size: 1.5em;
	text-decoration: none;
	text-transform: capitalize;
	&:hover,
	&:focus {
		background-color: $accordion-unselected-bgcolor;
		color: $secondary-font-colour;
	}
	.shown {
		display: block;
		text-align: right;
		margin: 0;
	}
	.hidden {
		display: none;
		margin: 0;
	}
}

.is-active .accordion-title {
	border: 1px solid $accordion-selected-bgcolor;
	background-color: $accordion-selected-bgcolor;
	.shown {
		display: none;
	}
	.hidden {
		display: block;
		text-align: right;
	}
}

// Remove default foundation expand/collapse icon
.is-active .accordion-title:before, .accordion-title:before {
	content: " ";
}

.accordion-content {
	display: block;
	background-color: $form-outer-bgcolor; // $very-pale-blue;
	border: 1px solid $tmr-blue;
    h4 {
        color: $primary-colour;
    }
}

.accordion-item {
    margin-bottom: 5px;
}

.accordion-title .delete {
	position: relative;
	bottom: 0em;
	left: 2em;
	@include small {
        position: initial;
        float: left;
	}
}



// This is used on the Person/vehicle page.
.questions .grid {
    padding: .5em;
    margin-bottom: .5em;
    background-color: $form-inner-bgcolor; //#e9f5f9;
}

.grid.actions {
    margin-top: 2em;
    width: 100%;
    justify-content: space-between;
    background-color: $form-outer-bgcolor; //$very-pale-blue;
}

// Width of this
.trips-header .grid.actions {
    width: 95%;
}

textarea {
    height: auto;
    margin-top: 1em;
}

legend,
label,
ul li {
    font-size: 1em;
}

input[type="radio"],
input[type="checkbox"] {
    &:checked + label:before {
        color: $bright-green;
    }
}

// Override foundation default.
:last-child>.accordion-content:last-child {
	border-bottom: 1px solid $tmr-blue;
}


.vertical-column {
    width: 95%;
    margin-top: 2em;
    @include tablet {
        flex-direction: column-reverse;

        .width-24 {
            width: 100%;
        }
        .width-61 {
            width: 100%;
        }
        .width-15 {
            width: 100%;
        }
        [class^='width-'],
        button {
            width: 100%;
        }
    }
    @include small {
        flex-direction: column-reverse;
        [class^='width-'],
        button {
            width: 100%;
        }
    }
    &:last-child {
        margin-right: 0;
    }
}



.trip-panel-header {
    background-color: $tmr-blue;
    color: $secondary-font-colour;
    padding: 1em;
    font-size: 1.125em;
    font-weight: bold;
    overflow: auto;
    h3 {
        float:left;
    }
    .fa {
        color: $very-dark-blue;
        float: right;
        font-size: 1.5em;
    }
}

.trip-panel-body {
    padding: 1em;
    background-color: $form-inner-bgcolor; // #e9f5f9;

    legend,
    p,
    button {
        margin: 1em 0;
        width: 100%;
    }
    select, .dropdown.btn-group {
        margin: 1em 0;
    }
    select[multiple] option {
        font-size: .8em;
    }
    .choice_v {
        margin-bottom: 1em;
        div {
            input[type="radio"],
            input[type="checkbox"] {
                &:checked + label:before {
                    color: $tmr-blue;
                }
            }
        }
    }
    .checkbox-group {
        .choice_v {
            margin-bottom: 0;
        }
        .choice_v:not(:first-child) {
            margin-top: 0;
        }
    }
    .grid {
        margin-top: 0;
    }
    .width-35 {
        margin-right: 3%;
    }
    a.has-tip {
        font-size: 1.25em;
    }
    .tooltip.bottom {
        border-color: $tmr-blue;
    }
    .statictext {
        background-color: $white;
        font-size: 1.125em;
        padding: 0.5em;
        border: 1px solid #cacaca;
    }
}

.question9 {
    background-color: #e9f5f9;
    padding: 1em;
    legend {
        color: $primary-colour;
        font-size: 1.1em;
        margin: 0;
    }
    p {
        font-size: 1.1em;
        margin: 0;
    }
    label {
        font-size: 1.5em;
    }
}

// trips

select {
    width: auto;
    margin-right: 0.5em;
    margin-bottom: 0;
}


// Custom trips header
.trips-header .accordion-title {
		width: 100%;
		list-style: none;
		padding: 0.5em;
		font-size: 1.5em;
		text-decoration: none;
		span {
			font-size: 22px;
			margin: auto .25em;
			vertical-align: middle;
			@include small {
				margin: 0;
			}
		}
		i {
			vertical-align: middle;
		}
		button {
			margin-bottom: 0;
		}
		.fa-question-circle {
			font-size: 1em;
		}
        .from_to {
            @include small {
              width: 86%;
        }
        div {
              display: inline-block;
              &.to {
                margin: 0 0.9em;
                vertical-align: top;
                text-transform: none;
              }
              &.trip_description {
                max-width: 42%;
                vertical-align: top;
                @include tablet {
                  max-width: 38%;
                }
                @include small {
                  max-width: 32%;
                }
                .trip-times {
                  font-size: 12px;
                }
              }
            }
    }
}

.timepicker {
    width: 55%;
    display: inline-block;
    select {
        margin-right: 1em;
    }
    @include small {
        width: 100%;
    }
}


.add-vehicle, .add-trip, .add-trip:hover, .add-trip:focus {
  width: 100%;
  border: 1px solid $add-thing-box-color !important;
  list-style: none;
  background-color: $body-bg;
  color: $add-thing-text-color;
  padding: 0.5em;
  font-size: 1.5em;
  text-decoration: none;
  h4 {
      display: inline;
      padding: 0.5em;
  }
  span {
    font-size: 22px;
    margin: auto .5em;
    color: $add-thing-text-color;
    vertical-align: middle;
    @include small {
        margin: 0;
    }
  }
  i {
    vertical-align: middle;
    @include small {
      margin-right: 1em;
    }
  }
  a.has-tip {
    margin-top: .3em;
  }
  select {
    width: auto;
    margin-right: 0.5em;
    margin-bottom: 0;
  }
  button {
    margin-bottom: 0;
  }
  .fa-question-circle {
    font-size: 1em;
  }
  [class^="width-"] {
    @include small {
        margin: .5em 0;
    }
  }
}

//HUWFIXES

//The following rules are overrides to make the buttons in the accordion move to the next line if the title is too long, without breaking the layout as they current do at mobile res.

div.accordian {
display: flex;
}

div.accordian-button-right {
  flex: 1 0 auto;
}
