.full {
  .home-page {
    &.container {
      padding: 1em 0;
    }
    h1 {
      border-bottom: 3px solid $primary-colour;
      color: $primary-colour;
      width: 100%;
    }
    p.not-your-day {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
}

.home-why {
  p {
    color: $primary-colour;
    &.home-more {
      position: relative;
      bottom: 45px;
      @include tablet {
        bottom: 0;
      }
    }
  }
  [class^='width-'] {
    position: relative;
    bottom: 70px;
    &::before {
      font-family: $fontawesome;
      content: "\f067";
      font-size: 50px;
      color: $grey;
      position: relative;
      top: 100px;
      left: 150px;
      @include tablet {
        display: none;
      }
    }
    &:last-child {
      bottom: 0;
      &::before {
        content:'';
      }
    }
    @include tablet {
      bottom: 0;
    }
  }
  .home-logo {
    width: 130px;
    height: 130px;
    border-radius: 65px;
    text-align: center;
    img {
      margin-top: 20px;
    }
    &.orange {
      background-color: $tmr-orange;
    }
    &.green {
      background-color: $tmr-green;
    }
    &.blue {
      background-color: $tmr-blue;
      img {
        padding-top: 10px;
      }
    }
    &.maroon {
      background-color: $tmr-maroon;
    }
    @include small {
      display: inline-block;
      margin-right: 1em;
      margin-bottom: 1em;
    }
  }
  .caption {
    max-width: 130px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 0.5em;
    color: $primary-font-colour;
    @include small {
      display: inline-block;
      text-align: left;
      margin-top: 40px;
      vertical-align: top;
    }
  }
}

.home-what {
  h1 {
    border-bottom: 3px solid $primary-colour;
    color: $primary-colour;
    width: 100%;
  }
  span {
    font-size: .9em;
  }
  }
  .caption {
    color: $primary-colour;
    margin: .5em 0 1em 0;
    font-weight: bold;
    font-size: 15px;
  }
  .house {
    margin-right: 2%;
  }

  @include small {
    width: 100%;
    margin: 2em 0;
  }
  .register {
    background-color: $very-soft-green;
    padding: 1em;
    margin-right: 2%;
    @include small {
      margin-right: 0;
    }
    &.unavaliable {
      .caption {
        margin-top: 15%;
      }
    }
  }
  .register,
  .house,
  .about {
    padding-top: 1em;
    height: auto;
  }
.home-code {
  button {
    margin: 0;
  }
  error-message p {
    margin: 22px 0 0 0;
    font-size: 90%;
  }
}

.home-privacy {
  .grid {
    background-color: $light-grey-blue;
    padding: 10px;
    p {
      color: $dark-mod-blue;
    }
    img {
      margin-left: 23px;
      margin-top: 45px;
    }
  }
}

.travel-survey-table {
  margin-top: 2em;
}
