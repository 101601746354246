body {
  font-family: $primary-font;
  color: $primary-font-colour;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
}

h2 {
  color: $primary-colour;
}

a {
  color: $link-blue;
  text-decoration: underline;
  @include colorTransition(200ms);
  &:hover,
  &:focus {
    color: $very-dark-blue;
  }
}

.as-link {
    @extend a;
}

.capitaliseme {
  text-transform: capitalize;
}

.upcase {
  text-transform: uppercase;
}

.lowcase {
  text-transform: lowercase;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.fa {
  font-family: $fontawesome;
}

.fa-chevron-right::before {
  font-family: $fontawesome;
  content: "\f054";
}

/* to remove the number counter added by browsers */
input[type=number].remove-spinner::-webkit-inner-spin-button,
input[type=number].remove-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].remove-spinner {
  -moz-appearance: textfield;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
