/*.main-content {
  overflow: hidden;
}*/

.info {
  margin-top: 2em;
  h1 {
    color: $strong-blue;
    font-size: 1.25em;
    font-weight: bold;
  }
  ul > li:not(.inner) {
    border-top: 2px solid $light-grey-blue;
    // -huwfix- Added margins
    padding-top:1em;
    padding-bottom:1em;
    // -huwfix- Border for last child
    &:last-child {
      border-bottom: 2px solid $light-grey-blue;
    }
  }

  ul {
    color: $primary-colour;
    list-style-type:none;
    > li:before {
      font-family: $fontawesome;
      content: $fa-var-circle;
      color: $info-dots-color;
      font-size: 0.8em;
      margin-right: 1em;
    }
  }
  ol {
    margin-left: 3em;
    > li:before {
      color: $bright-green;
    }
  }
  .fa {
    font-size: 1.5em;
  }
}

.fa {
  font-size: 1em;
  font-family: $fontawesome;
  &.orange {
    color: $tmr-theme-02;
  }
  &.red {
    color: $tmr-maroon;
  }
  &.blue {
    color: $tmr-blue;
  }
  &.green {
    color: $error-message-green;
  }
  &.notstarted {
    color: $tmr-maroon;
    &::before {
      content: $fa-var-exclamation-circle;
    }
  }
/*
  For now we are considering an invalid trip to just be incomplete.
  Rather than crippling the logic, let's just make them appear the same
  to the user. That makes it much easier to go back it we want.

  &.invalid {
    color: $error-message-red;
    &::before {
      content: $fa-var-times;
    }
  }
*/
  &.incomplete, &.invalid {
    color: $tmr-theme-02;
    &::before {
      content: $fa-var-exclamation-circle;
    }
  }
  &.complete {
    color: $tmr-theme-04;
    &::before {
      content: $fa-var-check;
    }
  }
}

.fa-question-circle { color: $tooltip-color; }

.left {
  float: left;
}

.right {
  float: right;
}

a.has-tip {
  border: none;
  i.fa {
    font-size: 1.125em
  }
}
.tooltip {
  max-width: 15rem !important;
  z-index: 9999;
  background-color: $body-bg;
  color: $primary-colour;
  border: 1px $bright-green solid;
  &.top::before {
    border-color: $bright-green transparent transparent;
  }
  &.bottom::before {
    border-color: transparent transparent $bright-green;
  }
  span {
    font-weight: normal;
    font-size: 14px !important;
    margin: 0 !important;
  }
  @include small {
    max-width: 90% !important;
    width: 90% !important;
    left: 5% !important;
  }
}

._720kb-datepicker-calendar {
  font-family: $primary-font;
  ._720kb-datepicker-calendar-header {
    background-color: $tmr-blue;
    color: $secondary-font-colour;
    a, span {
      color: $secondary-font-colour;
    }
  }
  ._720kb-datepicker-calendar-header:nth-child(even) {
    background-color: $very-pale-blue;
    a {
      color: $primary-colour;
    }
  }
  ._720kb-datepicker-calendar-days-header {
    background-color: $very-soft-green;
  }
}

datepicker > input {
  font-family: $primary-font!important;
}

.no-padding-x {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
