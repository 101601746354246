.login-panel {
	margin: 10px;
	padding: 10px;
	margin-top: 25px;
	border-radius: 10px;
	border: solid 1px $light-grey;
}

.login-panel--button {
	text-align:right;
	margin-bottom: 5px;
}

.login-panel--button button {
	margin:0;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 700;
}

.login-panel span {
	text-align: center;
}

@media (max-width:724px) {
	div.login-panel span {
		width:100%!important;
	}
	.login-panel--button {
		text-align: center;
		margin-right: 0px;
	}
}

.begin-survey-container {
display: flex;
}

.begin-survey-container > button {
	flex:1 1 auto;
}

.token-info {
	flex:1 1 auto;
	border: 1px solid $light-grey;
	border-radius:10px;
	padding:10px;
	margin-right: 10px;
	float: left;
	width: 100%;
	align-items: center;
	display: flex;
	margin-bottom: 5px;
	justify-content: center;
}

@media (max-width:724px) {
	.begin-survey-container {
		flex-direction: column;
	}
}

.token-info i {
	margin-right: 0.5rem;
}
