.student-page {
  .agentinfo {
    margin-top: 3em;
    p {
      font-size: 1.125em;
    }
  }
  .grid {
    .submit-message {
        text-align: center;
        font-weight: bold;
        color: $primary-colour;
        @include small {
          position: inherit;
        }
     }
  }
  .grid.student-submit {
    @include small {
      flex-direction: column-reverse;
      .width-50 {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
    @include tablet {
      flex-direction: column-reverse;
      .width-50 {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }
}
