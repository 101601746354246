// Variables
$status-color: (
    complete:    $error-message-green,
    incomplete:  $error-message-orange,
    invalid:     $error-message-red,
    notfound:    purple,
);

// General question-status
.question-status {
    float: right;
}

// Make these bigger if within question-status
.question-status .status-icon.fa {
    font-size: 18px;
}

// Full-width question type
.question-status {
    margin-right: 0;
    font-weight: normal;

    @include small {
        float: none;
        display: block;
    }
    i.fa.tmr-status {
        float: none;
    }
}

.callout.in-accordion {
    background-color: #e9f5f9;
    border-color: $desat-dark-blue;
}


// Setup status icon colours
.status-icon-complete    { @extend .fa-check                }
.status-icon-incomplete  { @extend .fa-exclamation-circle   }
.status-icon-invalid     { @extend .fa-times                }
.status-icon-notfound    { @extend .fa-question             }


@each $status in complete, incomplete, invalid, notfound {
    .question-status {
        &.tmr-#{$status} {
            color: map-get($status-color, $status);
        }
    }
}

.fa.tmr-status {
    font-size: 18px;
    @each $status in complete, incomplete, invalid, notfound {
        &.tmr-#{$status} {
            @extend .status-icon-#{$status};
        }
    }
}


.trip-panel-body {
    background-color: $form-outer-bgcolor;
    padding: 0;
}

.trip-panel-body .grid {
    background-color: $form-inner-bgcolor; //#e9f5f9;
    padding: 0.5em;
    margin: 0;
    margin-bottom: 0.5em;
}
