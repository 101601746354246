// WIP animations

.ng-hide-add, .ng-hide-remove {
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;
}

/*
.accordion-content {
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;
}

.accordion-content.ng-hide {
  opacity:0;
}

.ng-hide {
  opacity:0;
}
*/
