/*.login-page {
  input {
      margin-bottom: 0;
  }
  &.container {
    padding: 2em 0;
  }
  h2 {
    border-bottom: 3px solid $primary-colour;
    width: 100%;
  }
  button {
      width: 100%;
  }
  .privacy {
    .grid {
        background-color: $light-grey-blue;
        padding: 10px;
        p {
          color: $dark-mod-blue;
        }
        img {
          margin-left: 23px;
          margin-top: 45px;
        }
      }
    }
}
*/

.login-page {
  input {
      margin-bottom: 0;
  }
  &.container {
    padding: 2em 0;
  }
    .privacy {
    .grid {
        background-color: $light-grey-blue;
        padding: 10px;
        p {
          color: $dark-mod-blue;
        }
        img {
          margin-left: 23px;
          margin-top: 45px;
        }
      }
    }
}


.login-page-h2 {
border-bottom: 3px solid $primary-colour;
width: 100%;
}

.login-page-button {
width: 100%;
}

.login-page-banner > img {
  padding:5px;
}

.login-page-banner > img:last-child {
  padding-bottom:15px;
}

input.login-token {
  text-transform: uppercase;
}
