$typeahead-border-color: grey;

.typeahead-wrapper, .typeahead-input {
  width: 95%;
}

.typeahead-wrapper {
  position: relative;
}

.typeahead-list {
  background: white;
  border-top: $strong-blue 2px solid;
  border-right: 1px solid $typeahead-border-color;
  border-left: 1px solid $typeahead-border-color;
  border-bottom: 1px solid $typeahead-border-color;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  position: absolute;
  width: 95%;
  z-index: 999;
  &:before {
      border-top: 5px solid $strong-blue;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      width: 0;
      height: 0;
      content: "";
      display: block;
      position: absolute;
      left: 10px;
    }
}

.typeahead-item {
  padding: 6px 15px;
}

.typeahead-item-selected {
  background: $strong-blue;
  color: white;
}

.typeahead-item-highlight {
  color: #00695C;
}
