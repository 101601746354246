.privacy-page {
  &.container {
    padding: 2em 0;
  }
  h1 {
    color: $primary-colour;
    width: 100%;
    font-size: 2em;
  }

  .uni-logos {
      padding-top: 1em;
      padding-bottom: 1em;
      .width-50 {
          text-align: center;
      }
  }
  .qut_logo img {
      max-width: 75%;
      @include small {
          padding-top: 2em;
      }
  }
}

