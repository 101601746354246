.legend {
	background-color: $tmr-theme-06;
	padding:12px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
  flex-wrap: wrap;

	@media (max-width: 500px) {
		flex-direction: column;
	}
}

.legend__legend-title {
	margin-right: 5px;
	font-size: 16px;
	font-weight: 700;
	flex-direction: column;
	align-self: center;

	@media (max-width: 500px) {
		width: 100%;
		flex-direction: row;
		text-align: -webkit-center;
	}

}

.legend__legend-title > * {
display: table-cell;
vertical-align: middle;
color: $dark-blue;
}

.legend__legend-title > h2 {
	padding-left: 10px;
    font-size: 16px;
}

.legend__legend-title > i {
	padding-left: 5px;
}

.legend__legend-entries {
	display: flex;
	text-align: right;
	@media (max-width: 500px) {
		flex-direction: column;
	}
}

.legend__legend-entry {
	flex:1 1 auto;
	font-size: 12px;
	background-color: white;
	padding: 10px;
	margin: 5px;
	border-radius: 5px;
	display: flex;
  align-items: center;


		i {
			padding-right: 10px;
		}

	@media (max-width: 500px) {
		width: 100%;
		text-align: center;
	}

}
