.reveal {
  max-width: 900px;
  width: 75%;
  @include small {
    width: 100%;
    left: 0;
  }
  .modal {
    width: 100%;
    .title-bar {
      padding: 1.5em;
      border-bottom: none;
      h1 {
        color: $primary-colour;
        font-size: 1.75em;
        border-bottom: $primary-colour 2px solid;
        width: 100%;
        padding-bottom: 1em;
      }
      .modal-close {
        position: absolute;
        top: 30px;
        right: 30px;
        .fa-times {
          color: $primary-colour;
        }
      }
      @include small {
        padding: 0;
      }
    }
    .container {
      padding: 0 1.5em;
      width: 100%;
      .section {
        border-bottom: $light-grey 1px solid;
        margin-bottom: 1em;
      }
      h2 {
        border-bottom: none;
        color: $primary-colour;
        font-size: 1.2em;
      }
      h3 {
        color: $tmr-blue;
        font-size: 1.1em;
      }
      p {
        color: $primary-colour;
        line-height: 1.1;
        font-size: .9em;
      }
      img {
        margin: 1em;
        max-width: 80%;
      }
      span.blue.button {
        text-decoration: none;
        float: right;
      }
      span.green.button {
        text-decoration: none;
      }
      button.white {
        border: 1px $tmr-maroon solid;
      }
      @include small {
        padding: 0;
      }
      button, span.button {
        @include tablet {
          width: 100%;
        }
      }
    }
    .household-accordion,
    .trips-accordion {
      .accordion-title span {
        position: initial;
      }
    }
    &.home-why {
      [class^='width-'] {
        position: initial;
        margin-bottom: 1em;
        &::before {
          content: none;
        }
        img {
          margin-top: 20px;
        }
      }
      .caption {
        max-width: inherit;
        margin-top: 3em;
        text-align: left;
      }
    }
    &.share {
      ul li {
        padding: 1em;
        cursor: pointer;
        .fa-facebook-official::before {
          color: $dark-mod-blue;
        }
        .fa-twitter-square::before {
          color: $pure-blue;
        }
        .fa-envelope::before {
          color: $tmr-theme-02;
        }
      }
    }
    &.trips {
      h2 {
        font-size: 1em;
      }
      select, input {
        border: 2px solid $error-message-green;
      }
      .fa-check.green {
        color: $error-message-green;
        float: right;
        margin-top: 1em;
        font-size: 18px;
      }
    }
    &.registration {
      .title-bar {
        background-color: $primary-colour;
        padding: 0;
        margin-bottom: 1em;
        h1, h2 {
          color: $white;
          border-bottom: none;
          padding-bottom: 0;
        }
        img {
          margin: 0;
        }
        .modal-close {
          .fa-times {
            color: $white;
          }
        }
      }
    }
  }
}

.reveal-overlay{
  background-color: rgba(0,60,105,.8)
}

$motion-ui-classes: (
    chain: true,
    prefix: 'ng-',
    active: '-active',
);


.tooltip {
    @include mui-fade(
        $state: in,
        $duration: 0.25s,
        $delay: 0s
    );
}

.tooltip {
    @include mui-fade(
        $state: out,
        $from: 1,
        $to: 0,
        $duration: 0.25s,
        $delay: 0s
    );
}

.reveal-overlay {
    @include mui-fade($state: in);
}

.reveal-overlay {
    @include mui-fade(
        $state: out,
        $from: 1,
        $to: 0
    );
}

.reveal {
    @include mui-slide(
        $state: in, // Specify in or out
        $direction: down, // Can be top, right, bottom, or left
        $fade: true, // If true, the element fades simultaneously
        $duration: 0.50s,
        $timing: cubic-bezier,
        $delay: 0
    );
}

.reveal {
    @include mui-zoom(
        $state: out,
        $from: 1,
        $to: 0,
        $duration: 0.25s
    );
}
