
@mixin small {
  @media only screen and (max-width: #{$mobile-width}), (min-device-width: 250px) and (max-device-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 250px) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin colorTransition($time) {
  transition: background-color $time ease;
}

@mixin opacityTransition($time) {
  transition: opacity $time ease-in-out;
}

@mixin heightTransition($time) {
  transition: height $time ease-in-out;
}

@mixin off-screen {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
}

@mixin grid-item-width($size) {
  width: $size + %;
}
