.speech-bubble {
display: block;
border-radius: 10px;
padding:15px;
background-color: $tmr-theme-05;
position: relative;
margin:10px;
}

.speech-bubble--heading h1 {
	font-size: 20px;
	border-bottom: none!important;
	color:white!important;
	font-weight: 700;
	text-align: center;
}

.speech-bubble:before {

	    content: "";
	    position: absolute;
	    bottom: -15px;
	    left: 60px;
	    border: 0;
	    border-left-width: 30px;
	    border-bottom-width: 15px;
	    border-style: solid;
	    border-color: transparent $tmr-theme-05;
	    display: block;
	    width: 0;
}

.speech-bubble:after {

	    content: "";
	    position: absolute;
	    bottom: -15px;
	    left: 60px;
	    border: 0;
	    border-left-width: 10px;
	    border-bottom-width: 15px;
	    border-style: solid;
	    border-color: transparent #fff;
	    display: block;
	    width: 0;

}

.speech-bubble--blue {
	background-color: $tmr-theme-03;
}

.speech-bubble--no-pointer {
	background-color: $tmr-blue;
}

.speech-bubble--no-pointer:after {
content:none;
}

.speech-bubble--no-pointer:before {
content:none;
}
