/*

.title-bar {
  background-color: $body-bg;
}

.banner {
  overflow: hidden !important;
  background-color: $primary-colour;
  z-index: 0;
  h1 {
    color: $secondary-font-colour;
    font-size: 2.75rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 90%;
    color: $secondary-font-colour;
  }
  img {
      margin: 5px 0;
      @include tablet {
          margin-top: 25px;
      }
      @include small {
          margin-top: 0px;
      }
  }
  .logo img {
    margin: 5px 0;
  }
  .container {
    .grid {
        @include small {
            .width-35 {
                text-align: center;
            }
        }
    }
  }
  .subheader {
    margin: 0;
    background-color: $primary-colour;
    background-image:
    linear-gradient(
      to left,
      $primary-colour 35%,
      $primary-colour 35%,
      $tmr-blue 0%,
      $tmr-blue 0%
    );
    .container {
      @include small {
        margin: 0;
        width: 100%;
      }
      .grid {
        @include small {
          flex-direction: row;
        }
        .heading {
          padding: 1em 1em 1em 0;
          background-color: $tmr-blue;
          h2 {
            font-size: 100%;
            font-weight: bold;
            margin: 0;
          }
          @include small {
            width: 80%;
            padding: 1em 0 1em 1em;
          }
        }
        .pages {
          background: url("/images/fold.png") 110% 0 no-repeat, $dark-blue;
          background-color: $dark-blue;
          padding: 1em;
          @include tablet {
            padding: 1.75em 0 .5em 1em;
          }
          @include small {
            width: 20%;
          }
          span {
            color: $secondary-font-colour;
          }
        }
        .heading,
        .pages {
          height: 65px;
          @include tablet {
            height: 90px;
          }
        }
      }
    }
  }
}

*/

.title-bar {
  background-color: $body-bg;
}

.banner {
  overflow: hidden !important;
  background-color: $primary-colour;
  z-index: 0;
  h1 {
    color: $secondary-font-colour;
    font-size: 2.75rem;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  h2 {
    font-size: 90%;
    color: $secondary-font-colour;
  }
  img {
      margin: 10px;
      @include tablet {
          margin-top: 25px;
      }
      @include small {
          margin-top: 0px;
      }
  }
  .logo img {
    margin: 5px 0;
  }
  .container {
    .grid {
        @include small {
            .width-35 {
                text-align: center;
            }
        }
    }
  }
  .subheader {
    margin: 0;
    background-color: $primary-colour;
    background-image:
    linear-gradient(
      to left,
      $primary-colour 35%,
      $primary-colour 35%,
      $tmr-blue 0%,
      $tmr-blue 0%
    );
    .container {
      @include small {
        margin: 0;
        width: 100%;
      }
      .grid {
        @include small {
          flex-direction: row;
        }
        .heading {
          padding: 1em 1em 1em 0;
          background-color: $tmr-blue;
          h2 {
            font-size: 100%;
            font-weight: bold;
            margin: 0;
          }
          @include small {
            width: 80%;
            padding: 1em 0 1em 1em;
          }
        }
        .pages {
          background: url("/images/fold.png") 110% 0 no-repeat, $dark-blue;
          background-color: $dark-blue;
          padding: 1em;
          @include tablet {
            padding: 1.75em 0 .5em 1em;
          }
          @include small {
            width: 20%;
          }
          span {
            color: $secondary-font-colour;
          }
        }
        .heading,
        .pages {
          height: 65px;
          @include tablet {
            height: 90px;
          }
        }
      }
    }
  }
}
