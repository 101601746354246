.trips-page {
  margin-top: 2em !important;
}

.info {
    h3 {
        font-size: 1.5em;
        color: $primary-colour;
    }
    img {
        max-width: 94%;
        margin: 2%;
            @include small {
                margin: 1em 0;
            }
    }
}
.comments {
    legend,
    label {
        font-size: 85%;
    }

    .width-20 {
        margin-top: 1em;
    }
}

.trip-insertion-point {
    opacity: 0;
    height: 0px;
    p {
        display: none;
    }
    &.selected {
        opacity: 0.74;
        height: 57px;
        @include small {
        height: 100%;
        }
        border: 1px dashed $new-thing-here-box-color;
        background-color: $body-bg;
        color: $new-thing-here-text-color;
        padding: 0.5em;
        margin: 0.5em 0;
        p {
            font-size: 1.4em;
            display: block;
            margin-bottom: 0;
        }
    }
}

.between-trips-marker {
    @extend .trip-insertion-point.selected;
    background-color: rgba(233,232,215,.5);
    opacity: 1;
    a.has-tip i.fa {
        font-size: 1em;
    }
    &:hover {
        background-color: $tmr-stone;
    }
    @include tablet {
        button {
            width: 100%;
            margin: 0px;
        }
    }

    &.time-overlap {
        border: 1px dashed #fd8204;
    }
}

.grid.submit-message {
    i.status {
        font-size: 1.5em;
        position: relative;
        @include small {
            position: inherit;
        }
    }
    p {
        text-align: center;
        font-weight: bold;
        color: $primary-colour;
    }
}
.grid.trip-submit {
    @include tablet {
        flex-direction: column-reverse;
    }

    @include tablet {
        .width-80 {
            width: 100%;
        }
    }

    @include tablet {
        .width-20 {
            width: 100%;
        }
    }
    @include tablet {
        button {
            width: 100%;
        }
    }
}
