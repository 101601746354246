  .travel-survey-table {
  // -huwfix- Changed font size on travel day heading to fit on one line
  h2 {
    font-size: 1.6rem;
  }
  .fa {
    font-size: 1.4em;
  }
  .header-row {
    background-color: $home-table-header-bgcolor;
    border-left: 7px solid $home-table-header-bgcolor;
    color: $primary-colour;
    font-weight: bold;
    div {
      padding: 1em;
    }
    @include small {
      display: none !important;
    }
  }

  // -huwfix- A utility class to make 'About your household' fit inside the household row first column @ fullwidth.
  .u-padding-1 {
    padding:0px;
  }

  .error-row {
    background-color: $very-soft-red;
    #{$home-table-border}: 7px solid $strong-red;
    color: $primary-colour;
    font-weight: bold;
    padding: 1em;
    margin: 5px 0;
    p {
      margin-bottom: 0;
    }
  }

  .household-row {
    background-color: $household-row-bgcolor;
    #{$home-table-border}: 7px solid $household-row-border-color;
    color: $primary-colour;
    font-weight: bold;
    padding: 1em;
    margin: 5px 0;
    .inner {
      background-color: rgba(255, 255, 255, .4);
      padding: 1em;
      height: 85px;
      h3 {
        font-size: 1.3em;
        text-align: left;
        font-weight: bold;
        margin-top: 8px;
        span {
          margin-right: 0.5em;
        }
        @include tablet {
          font-size: 1em;
        }
      }
      button {
        margin: 0;
        @include small {
          width: 90%;
          font-size: 1.1em;
          .fa {
            margin: 0 1em;
          }
        }
      }
      @include small {
        height: auto;
      }
    }
    .status-cell {
      background-color: rgba(255, 255, 255, .6);
      p {
        font-size: 1em;
        margin-top: 8px;
        @include tablet {
          font-size: .8em;
        }
        @include small {
          font-size: 1.1em;
          background-color: rgba(255, 255, 255, .6);
          padding: 1em;
        }
        .fa.complete {
          color: $tmr-green;
        }
      }
      @include small {
        background-color: rgba(255, 255, 255, .4);
        padding: 1em;
      }
    }
  }

  .travel-note-row {
    background-color: $travel-note-row-bgcolor;
    #{$home-table-border}: 7px solid $travel-note-row-border-color;
    color: $primary-colour;
    font-weight: bold;
    padding: 1em;
    .trip-diary-survey-body {
      background-color: $travel-note-row-bgcolor !important;
    }
    h3 {
      font-size: 1.3em;
      text-align: left;
      font-weight: bold;
      margin-top: 8px;
      padding-left: 0.5em;
      span {
        margin-right: 1em;
      }
      @include tablet {
        font-size: 1em;
      }
    }
    .note {
      padding: 1em 2em;
      background: url("/images/table-fold.png") top right no-repeat, rgba(255, 255, 255, .5);
      h5 {
        color: $link-blue;
        border-bottom: 3px $link-blue solid;
        text-transform: uppercase;
        font-weight: bold;
      }
      ul li {
        font-weight: normal;
        font-size: .9em;
        &:first-child {
          padding-bottom: .5em;
        }
      }
    }
  }
  .person-survey-row {
    background-color: $travel-note-row-bgcolor;
    @if $home-table-border == border-left {
      border-left: 7px solid $travel-note-row-border-color;
    }
    color: $primary-colour;
    font-weight: bold;
    padding: 1em;
    [class^='width-'] {
      @include small {
        border-left: 7px solid $white;
      }
    }
    .person-survey-body tr {
      background-color: $travel-note-row-bgcolor !important;
    }
    .inner {
      background-color: rgba(255, 255, 255, .4);
      padding: 1em;
      height: 85px;
      @include small {
        height: auto;
        padding: 0.5em 1em;
      }
      p {
        font-size: 1em;
        margin: 0.75em 0 0.75em 1em;
        @include tablet {
          font-size: .8em;
        }
        @include small {
          font-size: 1.1em;
        }
      }
      &.person-name {
        border-left: 7px solid $white;
        @include small {
          border-left: none;
        }
      }
      &.status-cell {
        background-color: rgba(255, 255, 255, .6);
        p {
          @include small {
            font-size: 1.1em;
            background-color: rgba(255, 255, 255, .6);
            padding: 1em;
          }
          .fa.complete {
            color: $tmr-green;
          }
        }
        @include small {
          background-color: rgba(255, 255, 255, .4);
          padding: 1em;
        }
      }
      .unblock {
        display: inline-block;
      }
      .button {
        padding: 0.85em 2px;

        // -huwfix- Removed margin so button renders in center of box
        //margin: 0.5em 0;


        .fa {
          font-size: 1.3em;
        }
        @include small {
          width: 95%;
          margin: 0 1em 0.5em;
        }
      }
    }
  }
}


@include small {
  .home-survey .statustable {
    .house-record {
      margin-bottom: 1em;
      td {
        border-left: 7px solid $strong-green;
        div {
          background-color: rgba(255, 255, 255, .7);
        }
      }
    }
    .empty-row {
      height: 0;
    }
    .people-records td div {
      border-left: 7px solid $white;
    }
  }
}

//Huwfix - Format error message inside status cell nicely

.status-cell-flex {
  display: flex;
  align-items: center;
  text-align: center;
}

.status-cell-icon {
  flex: 0 0 auto;
}

.status-cell-msg {
  flex: 1 0 auto;
}
