.full {
  width: 100%;
  .container {
    width: 85%;
    margin: 0 auto;
    max-width: 56.25rem;
    min-width: 250px;
    padding: 0;
    @include small {
      width: 95%;
    }
    .grid {
      list-style: none;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      @for $i from 1 through 100 {
        .width-#{$i} {
          @include grid-item-width(#{$i});
          @include small {
            width: 100%;
          }
        }
      }
      @include small {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
