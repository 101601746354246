.tmr-icon.fa {
    font-size: 1em;
}

.red .tmr-icon {
    color: $tmr-maroon;
}

.orange .tmr-icon {
    color: $tmr-theme-02;
}

.blue .tmr-icon {
    color: $tmr-blue;
}

.green .tmr-icon {
    color: $tmr-green;
}
