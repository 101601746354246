.skip {
		position: absolute;
		top: -1000px;
		left: -1000px;
		height: 1px;
		width: 1px;
		text-align: left;
		overflow: hidden;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
		left: 0;
		top: 0;
		width: auto;
		height: auto;
		overflow: visible;
		// update colour to suit training banner and qld gov logo
		color: black; 
}
