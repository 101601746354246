.video {
	border-radius: 10px;
}

.video iframe {
	height:500px;
	width: 100%;
	border-radius: 10px;
}

.video-heading {
	color: black;
	font-size: 1.2em;
	font-weight: 700;
}

.video-link {
	margin-bottom: -15px;
	border-radius:10px;
	background-color:#eee;
	padding-top:10px;
	padding-bottom:10px;
	padding:10px;
}
