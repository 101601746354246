//homepage survey buttons

.survey_btn {
  height:62px;
}

.survey-btn-text {
  float:left;
  width: 75%;
}

//Utility class to make buttons full width

.survey-btn-fullwidth {
  width:100%;
}

@include small {
    .button {
        float: none;
        width: 100%;
    }
}

.exit-button {
    position: absolute;
    right: 0;
}
