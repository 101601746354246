form, ng-form {
  margin: 1em 0;
  legend,
  label {
    margin: 0;
    line-height: 1.8;
    font-weight: bold;
  }
  input[type='number'].specify-other {
    width: 100%;
  }
  label.specify_other {
    font-weight: normal;
    font-style: italic;
    font-size: 0.75em !important;
  }
  .choice_v {
    list-style-type:none;
    padding: 0;
    margin: 0;
    line-height: 1.6;
    div {
      text-indent: 0;
    }
    &.nested {
      margin-left: 1.5em;
      margin-top: 0;
    }
  }
  select {
    white-space:pre-wrap;
    height: auto !important;
  }
  .dropdown-multiselect {
    &.input-lg {
      height: 45px;
    //HUWFIX - Removed font-size declaration from form partial      font-size: 18px;
      line-height: 1.33;
      border-radius: 6px;
    }
    .btn-group {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      @include small {
        max-width: 90%;
      }
      button.btn {
        @include form-select;
//HUWFIX - Removed font-size declaration - font-size: 1em;
        width: 100%;
        height: auto;
        text-align: left;
        position: relative;
        float: left;
        cursor: default;
        margin: 0;
        @include small {
          max-width: 90%;
        }
      }
      &.open {
        ul.dropdown-menu {
          display: inline;
          max-height: 400px;
          overflow: auto;
          width: 452px;
          margin-top: 3em;
          input[type="text"] {
            margin-left: 0.3em;
          }
          span {
            cursor: default;
            &:hover, &:focus {
              background-color: $tmr-blue;
              color: $white;
            }
          }
          @include small {
            width: 100%;
          }
        }
      }
    }
    ul.dropdown-menu {
      display: none;
      position: absolute;
      float: left;
      top: 0;
      left: 0;
      z-index: 8;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      background-color: $white;
      list-style-type:none;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 4px;
      li {
        margin-bottom: 0;
        span {
          display: block;
          padding: 3px 20px;
          clear: both;
          line-height: 1.4;
          white-space: nowrap;
          text-decoration: none;
          color: $primary-font-colour;
          i {
            font-style: normal;
            position: relative;
            display: inline-block;
            &::before {
              font-family: $fontawesome;
              content: $fa-var-square-o;
              color: $unselected-grey;
              margin-right: 1em;
              cursor: pointer;

            }
            &.glyphicon-ok::before {
              color: $tmr-green;
              content: $fa-var-check-square-o;
            }
          }
        }
      }
    }
  }
  .stepper {
    @include tablet {
        display: inline-block !important;
    }
    button,
    div {
      width: 50px !important;
      height: 40px;
      margin: 1em 0;
      background-color: $body-bg;
      border: 1px solid $tmr-blue;
      font-size: 1.5em;
      text-align: center;
      @include tablet {
          display: inline-block;
      }
    }
    button {
      color: $tmr-blue;
      font-weight: bold;
      &:disabled {
        color: $unselected-grey;
      }
    }
  }
  .timepicker {
    display: inline;
  }
  // -huwfix- Added Ash's fix for red radio buttons in firefox
  input {
    &:required {
    box-shadow: none;
    }
  }


// -huwfix- This displays the labels in a block-style way
  input[type=radio]+label {
    display: inline-flex!important;
  }

  input[type="radio"] + label:before {
    content: $fa-var-circle-o;
    z-index: 1;
    position: relative;
  }
  input[type="radio"]:focus    + label,
  input[type="checkbox"]:focus + label {
    outline: 2px solid $light-grey;
    border-radius: 4px;
    padding: 2px;
  }

  input[type="radio"]:checked + label:before {
    content: $fa-var-dot-circle-o;
  }

  input[type="checkbox"] + label:before {
    content: $fa-var-square-o;
  }
  input[type="checkbox"]:checked + label:before {
    content: $fa-var-check-square-o;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin-left: 5%;
    @include off-screen;
    + label {
      display: inline;
      font-weight:normal;
      margin-top:0;
      margin-bottom:0;
      margin-left: 0.5em;
      cursor: pointer;
      &::before {
        font-family: $fontawesome;
        color: $checkbox-unselected-color;
        margin-right: 1em;
        cursor: pointer;
      }
      span {
        display: inline-block;
        width: 90%;
        float: right;
      }
    }
    &:checked + label:before {
      color: $checkbox-selected-color;
    }
  }
  input[type="number"],
  input[type="text"],
  input[type="tel"],
  select {
    margin: 1em 0;
//HUWFIX - removed font-size declaration - font-size: 1.125em;
    display: inline-block;
  }
  input[type="text"],
  select,
  textarea,
  .dropdown-multiselect .dropdown-toggle {
    max-width: 100%;
    @include tablet {
      max-width: 90%;
    }
  }
  input[type="number"],
  input[type="tel"]{
    width: 8em;
  }
  textarea {
    width: 100%;
    display: inline-block;
    @include tablet {
      max-width: 90%;
    }
  }
}

/* to remove the number counter added by browsers */
.removecounter {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

//Font weight on label override
.login-panel > span > label {
  font-weight: 700;
}

// Some overrides to get the comments page spacing more consistent.
.comments-page {
  .choice_v {
    // For <tmr-radio-button> controls, add a little more space at the bottom.
    margin-bottom: 0.75em;
  }
  select {
    // For <tmr-select> controls, bring them closer up to the label above.
    margin-top: 0.5em;
  }
  em {
    // For the instructions part of the tmr-question, make the text a little smaller
    font-size: 80%;
  }
  .instructions li {
    // For the extra dot points on the income question instructions, make the text match
    // the normal instructions above.
    font-size: 80%;
    font-style: italic;
  }
  ul.instructions {
    margin-bottom: 0.25em;
  }
}
